<template>
  <div id="table-container" class="employee-project-allocations">
    <table style="border-spacing: 0 0 !important">
      <tr>
        <td
          style="border: none !important; width: calc(100% / 3)"
          class="allocations-table-header"
        >
          {{
            $tf("rallocPage.employee.weeklyAllocation|Heti teljes allokáció")
          }}
        </td>
        <!--        <td style="border: none !important" class="allocations-table-header">-->
        <!--          {{ $tf("rallocPage.employee.readiness|Projekt készültség") }}-->
        <!--        </td>-->
        <WeekHeader
          v-for="weekData in sortedWeekData"
          :key="`${weekData.year}-${weekData.week}`"
          :year="weekData.year"
          :week="weekData.week"
          @open-conflict-overlay="openConflictOverlay"
        ></WeekHeader>
      </tr>
      <template v-for="project in projects" :key="project.id">
        <tr>
          <td rowspan="2" class="project-name" style="border: none !important">
            <div class="flex">
              <div
                class="circle"
                :style="{ backgroundColor: project.projectColor }"
              ></div>
              <div class="project-info">
                <span class="inline project-title">{{ project.name }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <!--          <td style="border: none !important">-->
          <!--            <UtilizationStatusBar-->
          <!--              :max="1"-->
          <!--              :value="projectWeekData?.readiness ?? 0"-->
          <!--            />-->
          <!--          </td>-->
          <td
            v-for="weekData in projectWeekData(project.id)"
            :key="weekData.week"
            class="content-container"
            :class="{
              'padding-left': weekData.firstCol,
              'no-right-border':
                (weekData.firstCol && !weekData.lastCol) ||
                (!weekData.firstCol &&
                  !weekData.lastCol &&
                  weekData.association),
              'padding-right': weekData.lastCol,
            }"
          >
            <div
              :style="{
                backgroundColor:
                  weekData.association?.projectData?.projectColor ??
                  'var(--blue)',
                boxShadow: !weekData.lastCol
                  ? `10px 0 ${
                      weekData.association?.projectData?.projectColor ??
                      'var(--blue)'
                    }`
                  : '',
              }"
              :class="{
                'project-card-first-col': weekData.firstCol,
                'project-card-last-col': weekData.lastCol,
              }"
              class="project-card-content"
              v-if="weekData.association"
            >
              <span>
                {{ convertHoursToMWD(weekData.association.hours) }}
                {{ $tf("rallocPage.mwd|MWD") }}
              </span>
            </div>
          </td>
        </tr>
      </template>
      <tr v-if="projects.length > 5">
        <td>
          <span class="too-much-projects"
            ><b-icon icon="triangle-exclamation"></b-icon>
            {{ $tf("rallocPage.tooMuchProjects|Túl sok projekt") }}</span
          >
        </td>
      </tr>
    </table>
    <b-modal
      v-model="conflictOverlayOpened"
      has-modal-card
      @close="closeConflictOverlay"
    >
      <div class="card conflict-overlay-card">
        <h2 class="title">
          {{
            $tf(
              "rallocPage.conflictsOverlay.employeeUtilization|Dolgozó utilizáltsága"
            )
          }}
        </h2>
        <p>
          <b>{{ selectedWeek }} {{ $tf("rallocPage.nthWeek|. hét") }}</b>
          <br />
          <span>{{ selectedDateRange }}</span>
        </p>
        <div style="display: flex; flex-direction: row">
          <div style="flex: 0 0 65%; padding: 12px">
            <b-table :data="[...weeklyAssociations, ...associationsToAdd]">
              <b-table-column
                v-slot="prop"
                :label="$tf('rallocPage.conflicts.project|Projekt')"
              >
                <b style="margin-bottom: 4px">{{
                  prop.row.projectData.identifier
                }}</b>
                <br />
                <template v-if="!prop.row.editing">
                  <b-icon
                    :icon="RALLOC_PRIORITY[prop.row.priority].icon"
                    style="display: inline-block; margin-right: 6px"
                  />
                  {{ $tf(RALLOC_PRIORITY[prop.row.priority].token) }}
                </template>
                <div v-else class="is-flex is-justify-content-start">
                  <b-select
                    v-model="prop.row.priority"
                    :placeholder="
                      $tf(
                        'ralloc.conflicts.employeeDetails.priority.placeholder|Foglalás kiválasztása'
                      )
                    "
                  >
                    <option :value="RALLOC_PRIORITY.LOW.value">
                      {{ $tf(RALLOC_PRIORITY.LOW.token) }}
                    </option>
                    <option :value="RALLOC_PRIORITY.MEDIUM.value">
                      {{ $tf(RALLOC_PRIORITY.MEDIUM.token) }}
                    </option>
                    <option :value="RALLOC_PRIORITY.HIGH.value">
                      {{ $tf(RALLOC_PRIORITY.HIGH.token) }}
                    </option>
                  </b-select>
                </div>
              </b-table-column>
              <b-table-column v-slot="prop">
                {{ prop.row.projectData.name }}
              </b-table-column>
              <b-table-column
                v-slot="prop"
                :label="$tf('rallocPage.conflicts.client|Ügyfél/Felelős')"
              >
                <b>{{
                  prop.row.projectData.clientName ??
                  prop.row.projectData.clientId
                }}</b>
                <br />
                {{
                  prop.row.projectData.leadUserName ??
                  prop.row.projectData.leadUserId
                }}
              </b-table-column>
              <b-table-column
                v-slot="prop"
                :label="
                  $tf('rallocPage.conflicts.allocated|Allokált erőforrás')
                "
              >
                <template v-if="!prop.row.editing">
                  {{ prop.row.hours / 8 }} {{ $tf("rallocPage.mwd|MWD") }}
                  <clickable-icon
                    class="ml-2"
                    icon="pencil"
                    @click="prop.row.editing = true"
                  />
                </template>
                <template v-else>
                  <div class="is-flex has-gap-1">
                    <button
                      class="button is-flex is-align-items-center"
                      @click="handleMinusClick(prop.row)"
                    >
                      <b-icon icon="minus" />
                    </button>

                    <b-input
                      disabled
                      class="mx-2"
                      custom-class="has-text-centered"
                      :value="prop.row.hours / 8 + ' MWD'"
                    />
                    <button
                      class="button is-flex is-justify-content-center is-align-items-center"
                      @click="handlePlusClick(prop.row)"
                    >
                      <b-icon icon="plus" />
                    </button>
                  </div>
                </template>
              </b-table-column>
            </b-table>
          </div>
          <div
            style="flex: 0 0 32%; padding: 12px; text-align: left !important"
          >
            <user-info display-mode="full" :user="allocations.employee" />
            <h6>
              {{
                $tf(
                  "ralloc.conflicts.employeeDetails.title|Utilizációs információk"
                )
              }}
            </h6>
            <div>
              <span>
                {{
                  $tf(
                    "ralloc.conflicts.employeeDetails.workSchedule|Munkaszerződés:"
                  )
                }}
              </span>
              <span class="has-font-weight-700">
                {{
                  ` ${weeklyAllocations.expectedHours} ${$tf(
                    "rallocPage.hours| óra"
                  )}`
                }}
              </span>
            </div>
            <div>
              <span>
                {{
                  $tf(
                    "ralloc.conflicts.employeeDetails.expectedHours|Heti elérhető erőforrás:"
                  )
                }}
              </span>
              <span class="has-font-weight-700">
                {{
                  " " +
                  weeklyAllocations.expectedHours / 8 +
                  $tf("rallocPage.mwd| MWD")
                }}
              </span>
            </div>
            <div>
              <span>
                {{
                  $tf(
                    "ralloc.conflicts.employeeDetails.allocatedHours|Heti foglalt erőforrás:"
                  )
                }}
              </span>
              <span class="has-font-weight-700">
                {{
                  " " +
                  weeklyAllocations.allocatedHours / 8 +
                  $tf("rallocPage.mwd| MWD")
                }}
              </span>
            </div>
            <div>
              <span>
                {{
                  $tf(
                    "ralloc.conflicts.employeeDetails.utilization|Utilizáció:"
                  )
                }}
              </span>
              <span
                class="has-font-weight-700"
                :class="
                  weeklyAllocations.allocatedHours /
                    weeklyAllocations.expectedHours >
                  1
                    ? 'has-text-danger'
                    : 'has-text-warning'
                "
              >
                {{
                  " " +
                  percentify(
                    weeklyAllocations.allocatedHours /
                      weeklyAllocations.expectedHours
                  )
                }}
              </span>
            </div>
          </div>
        </div>

        <div style="display: flex; flex-direction: row; padding-top: 28px">
          <div style="flex: 0 0 75%">
            <b-field label="Projekt" label-position="on-border">
              <b-autocomplete
                :ref="`ac-${allocations.employee.id}-${selectedWeek}`"
                rounded
                v-model="projectSearch[allocations.employee.id]"
                :data="filteredProjects"
                :custom-formatter="projectFormatter"
                icon="search"
                clearable
                expanded
                field="name"
                @select="
                  (option) =>
                    (selectedProject[allocations.employee.id] = option)
                "
                :placeholder="
                  $tf(
                    'ralloc.conflicts.employeeDetails.projectPicker.placeholder|Projekt keresése...'
                  )
                "
              >
                <template #empty>{{
                  $tf(
                    "ralloc.conflicts.employeeDetails.projectPicker.notFound|Nincs találat..."
                  )
                }}</template>
              </b-autocomplete>
              <b-select
                v-model="selectedPriority[allocations.employee.id]"
                class="mx-5"
                expanded
                :placeholder="
                  $tf(
                    'ralloc.conflicts.employeeDetails.priority.placeholder|Foglalás kiválasztása'
                  )
                "
              >
                <option :value="RALLOC_PRIORITY.LOW.value">
                  {{ $tf(RALLOC_PRIORITY.LOW.token) }}
                </option>
                <option :value="RALLOC_PRIORITY.MEDIUM.value">
                  {{ $tf(RALLOC_PRIORITY.MEDIUM.token) }}
                </option>
                <option :value="RALLOC_PRIORITY.HIGH.value">
                  {{ $tf(RALLOC_PRIORITY.HIGH.token) }}
                </option>
              </b-select>
              <b-button
                icon-left="plus"
                type="is-primary"
                class="ml-2"
                :disabled="!selectedProject[allocations.employee.id]"
                @click="addProject()"
              >
                {{
                  $tf(
                    "ralloc.conflicts.employeeDetails.newAllocation|Új allokáció hozzáadása"
                  )
                }}
              </b-button>
            </b-field>
          </div>
          <div style="flex: 0 0 24%">
            <b-button
              class="is-pulled-right"
              type="is-primary"
              icon-left="save"
              @click="saveAssociation"
              :loading="saveEmployeeButtonLoading"
            >
              {{ $tf("ralloc.conflicts.employeeDetails.save|Mentés") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import WeekHeader from "@/components/ralloc/project-allocations/WeekHeader.vue";
import { mapGetters } from "vuex";
import {
  convertHoursToMWD,
  percentify,
  formatDate,
  generateNext6Weeks,
  getWeekDataBetween,
} from "@/utils/util";
import { RALLOC_PRIORITY } from "@/utils/const";
import ClickableIcon from "../../module/icon/ClickableIcon.vue";
import UserInfo from "../../module/info/UserInfo.vue";

export default defineComponent({
  name: "EmployeeProjectAllocations",
  components: { WeekHeader, ClickableIcon, UserInfo },
  props: {
    allocations: {
      type: Object,
      required: true,
    },
    fromDate: {
      type: String,
      default: new Date(),
    },
    toDate: {
      type: String,
      default: new Date(),
    },
  },
  data() {
    return {
      RALLOC_PRIORITY,
      conflictOverlayOpened: false,
      selectedWeek: null,
      selectedYear: null,
      selectedDateRange: null,
      projectSearch: {},
      selectedProject: {},
      selectedPriority: {},
      associationsToAdd: [],
      weeklyAssociations: [],
      saveEmployeeButtonLoading: false,
    };
  },
  computed: {
    sortedWeekData() {
      if (!this.fromDate || !this.toDate) {
        return [];
      }

      return getWeekDataBetween(this.fromDate, this.toDate);
    },
    projects() {
      return [
        ...new Map(
          this.allocations.weekData
            .flatMap((item) => item.associations)
            .map((item) => item.projectData)
            .map((item) => [item.id, item])
        ).values(),
      ].map((item) => ({
        lead: this.resolveLeadName(item.leadUserId),
        ...item,
      }));
    },
    weeklyAllocations() {
      return this.selectedWeek
        ? this.allocations.weekData.find(
            (data) => data.week === this.selectedWeek
          )
        : [];
    },
    ...mapGetters({
      employees: "employee/loggingEmployees",
      limitedProjects: "enterprise_core/limitedProjects",
    }),
    filteredProjects() {
      let projects = this.limitedProjects || [];
      return projects.filter((element) => {
        let passesFilter = true;
        if (this.projectSearch[this.allocations.employee.id]) {
          passesFilter =
            passesFilter &&
            (element.name
              .toString()
              .toLowerCase()
              .indexOf(
                this.projectSearch[this.allocations.employee.id].toLowerCase()
              ) >= 0 ||
              element.identifier
                .toString()
                .toLowerCase()
                .indexOf(
                  this.projectSearch[this.allocations.employee.id].toLowerCase()
                ) >= 0) &&
            !this.weeklyAssociations
              .map((p) => p.projectId)
              .includes(element.id);
        }
        return passesFilter;
      });
    },
  },
  methods: {
    convertHoursToMWD,
    percentify,
    addProject() {
      this.associationsToAdd.push({
        editing: true,
        hours: 0,
        priority:
          this.selectedPriority[this.allocations.employee.id] ??
          this.RALLOC_PRIORITY.LOW.value,
        projectId: this.selectedProject[this.allocations.employee.id]?.id,
        type: this.selectedProject[this.allocations.employee.id]?.type,
        projectData: {
          identifier:
            this.selectedProject[this.allocations.employee.id]?.identifier,
          name: this.selectedProject[this.allocations.employee.id]?.name,
          leadUserId:
            this.selectedProject[this.allocations.employee.id]?.leadUserId,
          leadUserName:
            this.selectedProject[this.allocations.employee.id]?.leadName,
          clientId:
            this.selectedProject[this.allocations.employee.id]?.clientId,
          clientName:
            this.selectedProject[this.allocations.employee.id]?.clientName,
        },
      });
    },
    projectFormatter(project) {
      return `${project.identifier} - ${project.name}`;
    },
    openConflictOverlay(week, dateRange, year = null) {
      this.conflictOverlayOpened = !this.conflictOverlayOpened;
      this.selectedWeek = week;
      this.selectedYear = year;
      this.selectedDateRange = dateRange;
      this.weeklyAssociations = this.allocations.weekData.find(
        (data) => data.week === this.selectedWeek
      ).associations;
    },
    closeConflictOverlay() {
      this.conflictOverlayOpened = false;
      this.selectedWeek = null;
      this.selectedYear = null;
      this.selectedDateRange = null;
      this.weeklyAssociations = [];
      this.saveEmployeeButtonLoading = false;
    },
    resolveLeadName(id) {
      return this.employees.find((e) => e.id === id)?.name;
    },
    projectWeekData(projectId) {
      const sortedData = this.sortedWeekData.map((item) => ({
        association: item.associations?.find(
          (assoc) => assoc.projectId === projectId
        ),
        ...item,
      }));

      return sortedData.map((item, i) => ({
        firstCol: this.isFirstCol(sortedData, item, i),
        lastCol: this.isLastCol(sortedData, item, i),
        ...item,
      }));
    },
    isFirstCol(dataArray, item, i) {
      if (!item.association) {
        return false;
      }

      if (i === 0) {
        return true;
      }

      return !dataArray[i - 1].association;
    },
    isLastCol(dataArray, item, i) {
      if (!item.association) {
        return false;
      }

      if (i === dataArray.length - 1) {
        return true;
      }

      return !dataArray[i + 1].association;
    },
    projectMergedColumns(projectId) {
      const weekData = this.projectWeekData(projectId);
      const result = [];
      let counter = 0;
      for (const [i, data] of weekData.entries()) {
        if (data.association) {
          counter++;
          if (weekData.length > i + 1 && weekData[i + 1].association) {
            continue;
          }
        }
        result.push({
          columnSpan: counter !== 0 ? counter : 1,
          ...data,
        });
        counter = 0;
      }

      return result;
    },
    getAllocatedHoursColor(weekData) {
      if (weekData.allocatedHours < weekData.expectedHours) {
        return "var(--custom-orange)";
      } else if (weekData.allocatedHours > weekData.expectedHours) {
        return "var(--red)";
      } else {
        return "var(--green)";
      }
    },
    handleMinusClick(association) {
      if (association.hours % 4 === 0) {
        association.hours = association.hours > 4 ? association.hours - 4 : 0;
      } else
        association.hours = association.hours - (4 - (association.hours % 4));
    },
    handlePlusClick(association) {
      association.hours =
        association.hours % 4 === 0
          ? association.hours + 4
          : association.hours + (association.hours % 4);
    },
    async saveAssociation() {
      this.saveEmployeeButtonLoading = true;
      await this.$store.dispatch("association/updateAssociation", {
        employeeId: this.allocations.employee.id,
        year: this.selectedYear,
        week: this.selectedWeek,
        projects: [
          ...this.weeklyAssociations,
          ...this.associationsToAdd,
        ].filter((project) => project.editing),
      });
      await this.fetchUtilization(true);
      this.$emit("fetch-allocations");
      this.saveEmployeeButtonLoading = false;
      this.closeConflictOverlay();
    },
    async fetchUtilization(force = false) {
      this.loading = true;
      await this.$store.dispatch("association/getUtilization", {
        from: formatDate(this.fromDate),
        to: formatDate(this.toDate),
        force,
      });
      this.loading = false;
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/scss/media-queries";

.employee-project-allocations {
  .modal .animation-content {
    margin: 0 20px;
    min-width: 1200px !important;
    max-width: $screen-lg-max !important;
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/scss/colors";

.conflict-overlay-card {
  padding: 24px;
  text-align: center;
  color: $custom-dark-blue;
}

#table-container {
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px 12px 12px 0;
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;

  .allocations-table-header {
    color: $custom-dark-blue !important;
    font-size: 10px !important;
    text-align: left !important;
  }

  table {
    border-radius: 0 0 !important;
  }

  tr:first-child {
    td {
      text-align: center;
    }
  }

  tr:not(:first-child) td {
    border-bottom: none;
  }

  td:first-child {
    position: sticky;
    left: 0;
    // z-index: 11111;
  }

  td {
    white-space: normal;
    border: unset;
  }

  td:not(:last-child) {
    border-right: 1px solid var(--grey-lighter) !important;
  }

  tr:first-child td:first-child,
  tr:nth-child(2) td:first-child {
    border-right: 1px solid var(--grey-lighter) !important;
    background-color: var(--white);
  }

  tr td:not(:last-child) {
    border-right: 1px solid var(--grey-lighter) !important;
  }

  .project-card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 8px;
    color: white;
    height: 100%;
  }

  .project-card-first-col {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .project-card-last-col {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .project-info {
    line-height: 1rem;
  }

  .padding-left {
    padding-left: 0.75rem !important;
  }

  .padding-right {
    padding-right: 0.75rem !important;
  }

  .content-container {
    padding-left: 0;
    padding-right: 0;
  }

  tr:nth-child(odd) td:first-child {
    border-right: 1px solid var(--grey-lighter) !important;
    width: 12rem;
    background-color: var(--white);
    // z-index: 9999999;
  }

  .allocation-sum {
    font-size: 1rem;
    text-align: center;
    color: var(--custom-dark-blue);
  }

  .project-name {
    font-weight: 700;
    margin-right: 6px;
  }

  .too-much-projects {
    color: var(--red);
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}
td,
th {
  display: table-cell;
  vertical-align: middle;
  word-wrap: break-word;
}

tr:first-child td {
  padding-bottom: 0;
}

td {
  width: 12rem;
}

.circle {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 4px;
}

.flex {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 6px;
}

.inline {
  display: inline-block;
}

.project-title {
  font-weight: 400;
  font-size: 12px;
  padding-top: 12px;
}

.bold {
  font-weight: 700;
}

.project-lead {
  font-size: 12px;
}

.project-card-content {
  padding: 6px 8px;
  color: white;
  height: 100%;
  font-size: 12px;
  text-align: center;
}

.project-name {
  border-right: 1px solid $grey-lighter !important;
}
</style>
